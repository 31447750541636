<template>
  <b-col style="padding-top: 100px;" data-anima="bottom" cols="12" sm="12" md="12" lg="7">
    <div class="step-mainindication-container">
      <div class="mainobjective-title-container">
        <span class="mainindication-title"> {{ $t("onboarding.how_did_you_meet_greenn") }}</span>
        <span class="mainindication-title-count-step" style="word-wrap: nowrap;"> 05 {{ $t("onboarding.of") }} 05</span>
      </div>
      <IndicationSelect
        v-model="indication"
        :options="items"
      />
      <div v-show="indication.value == 'indicacao' || indication.value == 'evento'">
        <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="mainindication-title" v-show="indication.value == 'indicacao'">{{ $t("onboarding.who_did_you_nominate") }}
            <span class="optional">{{ $t("onboarding.optional") }}</span>
          </span>
          <span class="mainindication-title" v-show="indication.value == 'evento'">{{ $t("onboarding.what_event") }}
            <span class="optional">{{ $t("onboarding.optional") }}</span>
          </span>
          <span class="mainindication-title-count-step"></span>
        </div>
        <div class="description-container">
          <div
            class="description-length"
            :class="{ danger: indicationValue.length > 255 }"
            v-if="indicationValue.length"
          >
            {{ indicationValue.length }}
          </div>
          <input
            style="padding-left: 30px"
            v-model="indicationValue"
            class="mainindication-item"
            type="text"
            :class="{ 'border-danger': indicationValue.length > 255 }"
            :placeholder="$t('onboarding.tell_us_here')"
          />
          <span class="invalid-feedback" v-if="indicationValue.length > 255">
            {{ $t("onboarding.error_met_greenn") }}
          </span>
        </div>
      </div>

      <div v-show="indication.value == 'outro'">
        <div style="margin-top: 40px;" class="mainobjective-title-container">
          <span class="mainindication-title"> {{ $t("onboarding.specify_how_you_met_greenn") }}
            <span class="optional">{{ $t("onboarding.optional") }}</span>
          </span>
          <span class="mainindication-title-count-step"></span>
        </div>
        <div class="description-container">
          <div
            class="description-length"
            :class="{ danger: indicationValue.length > 255 }"
            v-if="indicationValue.length"
          >
            {{ indicationValue.length }}
          </div>
          <textarea
            style="padding-left: 30px"
            v-model="indicationValue"
            class="mainindication-item-area"
            type="text"
            row="30"
            :class="{ 'border-danger': indicationValue.length > 255 }"
            :placeholder="$t('onboarding.specify_how_you_met_greenn')"
          />
          <span class="invalid-feedback" v-if="indicationValue.length > 255">
            {{ $t('onboarding.error_met_greenn') }}
          </span>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between;">
        <div @click="prevStep" class="mainindication-btn">
          <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('onboarding.previous') }}
        </div>
        <div @click="nextStep" 
            class="mainindication-btn-next">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import IndicationSelect from './IndicationSelect.vue'
import { EventBus } from '@/main.js'

export default {
  name: 'MainIndication',
  components: {
    IndicationSelect
  },
  data() {
    return {
      indication: {
        text: 'Mídia Social',
        value: 'midia_social'
      },
      indicationValue: "",
      items: [
        {
          text: 'Mídia Social',
          value: 'midia_social'
        },
        {
          text: 'Buscador',
          value: 'buscador'
        },
        {
          text: 'Publicidade',
          value: 'publicidade'
        },
         {
          text: 'Indicação',
          value: 'indicacao'
        },
        {
          text: 'Evento',
          value: 'evento'
        },
        {
          text: 'Outro',
          value: 'outro'
        },
      ]
    }
  },
  watch: {
    indication() {
      this.indicationValue = '';
    }
  },
  methods: {
    resetAnimation () {
      const mainindication = document.querySelector('.step-mainindication-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 5000,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return mainindication.animate(keyframes, options);
    },
    nextStep() {
      if(this.indicationValue?.length > 255){
        EventBus.$emit('errorNotify', this.$t('onboarding.error_met_greenn'))
        return
      }
      const mainindication = document.querySelector('.step-mainindication-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = mainindication.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('update-step', { 
          currentStep: 'mainindication', 
          newStep: 'done',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_done"
            },
            {
              meta_key: "onboarding_indication",
              meta_value:"done"
            },
            {
              meta_key: "DISCOVERY_SOURCE",
              meta_value: this.indication?.value
            },
            {
              meta_key: "INDICATION_REFERRER_RESPONSE",
              meta_value: this.indicationValue || 'none'
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const mainIndication = document.querySelector('.step-mainindication-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = mainIndication.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('prev-step', { 
          currentStep: 'mainindication', 
          newStep: 'bankaccounttype',
        });
        this.resetAnimation();
      }
    }
  }
}
</script>

<style lang="scss">

.invalid-feedback{
  display: block;
}

.description-length {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: var(--primary-light);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -40px;
  right: 0;

  &.danger {
    color: var(--red);
    background: var(--red-light);
  }
}

.description-container {
  position: relative;
}

.step-mainindication-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}
.mainindication-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.mainindication-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px !important;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  border: none !important;
  margin-top: 15px;
}

.mainindication-item-area {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 140px !important;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  border: none !important;
  margin-top: 15px;
}

.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainindication-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(154, 154, 154, 1);
}
.optional{
    font-size: 16px;
    font-weight: 400;
    color: rgba(154, 154, 154, 1);
  }
.mainindication-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
  margin-bottom: 50px;
}
.mainindication-btn-next {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #009488;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.mainindication-btn-next-disabled {
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #c4c4c4;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.mainindication-select {
  display: flex;
  max-height: 70px;
  min-height: 70px;
  align-items: center;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
}
#social-dropdown button:focus {
  outline: none !important;
  border: none;
}
#social-dropdown {
  outline: none;
  border: none;
  width: 100%;
}
#social-dropdown__BV_toggle_ {
  outline: none !important;
  border: none !important;
}
.dropdown-item-social {
  display: flex;
  min-width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 6px;
  padding-right: 9px;
}
</style>