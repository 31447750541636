<template>
  <b-col style="padding-top: 70px;" data-anima="bottom" cols="12" sm="12" md="12" lg="7">
    <div class="step-mainobjetive-container">
      <div class="mainobjective-title-container">
        <span class="mainobjetive-title"> {{ $t("onboarding.objetive.introduction") }} </span>
        <span class="mainobjetive-title-count-step"> 01 {{ $t("onboarding.of") }} 05</span>
      </div>
      <span @click="nextStep(option.value)" :key="option.text" v-for="option in options" class="mainobjetive-item">
        <svg style="margin-left: 25px; margin-right: 17px; min-width: 16px; min-height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7" stroke="" stroke-opacity="0.5" stroke-width="2"/>
        </svg>

        {{ option.text }}
      </span>
      <div @click="prevStep" class="mainobjetive-btn">
        <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t("onboarding.previous") }}
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'MainObjetive',
  data() {
    return {
      options: [
        {
          value: 'produtos',
          text: this.$t('onboarding.objetive.i_want_to_sell_my_products_online'),
        },
        {
          value: 'afiliado',
          text: this.$t('onboarding.objetive.i_want_to_sell_products_from_other_producers')
        },
        {
          value: 'co_produtor',
          text: this.$t('onboarding.objetive.i_want_to_sell_products_from_other_producers_and_my_own'),
        }
      ],
      fbq: null
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.step === 'mainobjetive') {
        this.resetAnimation()
      }
    }
  },
  methods: {
    resetAnimation() {
      const mainobjetive = document.querySelector('.step-mainobjetive-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 0,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return mainobjetive.animate(keyframes, options);
    },
    nextStep(optionSelected) {
      const mainobjetive = document.querySelector('.step-mainobjetive-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      if (optionSelected === "produtos") {
        fbq('trackCustom', 'Produtor');
      }
      const animation = mainobjetive.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('update-step', {   
          currentStep: 'mainobjetive', 
          newStep: 'alreadysellonline',
          data: [
            {
              meta_key: 'onboarding_step',
              meta_value: 'onboarding_revenue'
            },
            {
              meta_key: 'onboarding_objective',
              meta_value: 'done'
            },
            {
              meta_key: 'TYPE_USER',
              meta_value: optionSelected
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const mainobjetive = document.querySelector('.step-mainobjetive-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = mainobjetive.animate(keyframes, options);
      animation.onfinish = () => {
        this.$router.push('/boas-vindas?step=welcome')
        this.$emit('prev-step', { currentStep: 'mainobjetive', newStep: 'document' });
        this.resetAnimation();
      }
    },
  }
}
</script>

<style lang="scss">
.step-mainobjetive-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
}
.mainobjetive-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.mainobjetive-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  svg {
    stroke: #81858E;
  }
}

.mainobjetive-item:hover {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  background: rgba(229, 244, 243, 1);
  color: rgba(0, 148, 136, 1);
  display: flex;
  align-items: center;
  margin-top: 15px;
  svg {
    stroke: rgba(0, 148, 136, 0.5) !important;
  }
}
.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.mainobjetive-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(154, 154, 154, 1);
}
.mainobjetive-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
}
</style>