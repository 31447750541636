<template>
  <div class="done-container">
    <b-col data-anima="bottom" cols="12" sm="12" md="6">
      <div class="done-header">
        <img class="done-logo" v-if="!isHeaven" src="@/assets/logo.png" />
        <img class="done-logo" v-else src="@/assets/img/icons-heaven/logo.svg" />
      </div>
      <div class="step-container">
        <span class="done-title">{{ $t("onboarding.introduction_done") }}</span>
        <span class="done-description">
          {{ $t("onboarding.description_done") }}
        </span>
        <span class="done-description">
          {{ $t("onboarding.yout_journey_with_the") }} 
          {{ !isHeaven ? 'Greenn' : 'Heaven' }} 
          {{ $t("onboarding.start_now") }}
        </span>
        <div @click="nextStep" class="done-btn">
          {{ $t("onboarding.go_to") }} {{ !isHeaven ? 'Greenn' : 'Heaven' }}
        </div>
      </div>
    </b-col>
    <b-col class="pr-0 mr-0" data-anima="bottom" cols="12" sm="12" md="6">
      <div class="heaven-bg" v-if="isHeaven">
        <img height="100" data-anima="right" src="@/assets/img/icons-heaven/logo.svg" alt="img">
      </div>
      <img style="min-height: 100%; object-fit: cover;" v-else data-anima="right" src="@/assets/img/cadastro-bg.png" alt="img">
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'DoneStep',
  props: ['goToDashboard'],
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    resetAnimation() {
      const done = document.querySelector('.step-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 0,
        easing: 'ease-out',
        fill: 'forwards'
      };
      done.animate(keyframes, options);
    },
    nextStep() {
      const done = document.querySelector('.step-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = done.animate(keyframes, options);
      animation.onfinish = () => {
        this.goToDashboard();
      }
    }
  }
}
</script>

<style lang="scss">
.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  padding: 56px;
  padding-right: 0px;
  max-width: 100%;
  overflow: hidden;
}
.done-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}
.done-description {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
  color: #81858E;
}
.done-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #009488;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.done-header {
  display: flex;
  align-items: center;
  height: 10vh;
  width: 100%;
  padding-left: 56px;
}
.done-logo {
  height: 34px;
}
.done-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  padding-right: 0px;
  flex-wrap: nowrap;
  max-height: 100vh;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .step-container {
    padding: 16px;
  }
  .done-header {
    padding-left: 16px;
  }

}
.heaven-bg {
  display: flex;
  background: radial-gradient(circle, $base-color-heaven-light 32%, $base-color-heaven 100%);
  background-color: #333;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  z-index: 88888;
  right: 0 !important;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>