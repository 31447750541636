import Rest from '@/services/Rest';

/**
 * @typedef {StepService}
 */
export default class StepService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/last-onboard-step'
}
