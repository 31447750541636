import Rest from '@/services/Rest';

/**
 * @typedef {MetaService}
 */
export default class MetaService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/user-meta'
}
