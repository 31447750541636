<template>
  <b-col style="padding-top: 50px;" data-anima="bottom" cols="12" sm="12" md="12" lg="7">
    <div class="step-documentstep-container">
      <div style="margin-top: 40px;" class="mainobjective-title-container">
        <span class="documentstep-title"> {{ $t("to_begining") }}</span>
        <span class="documentstep-title-count-step"></span>
      </div>
      <b-form-input
        style="padding-left: 30px;" 
        v-model="documentNumber" 
        class="documentstep-item" 
        type="text"
        :disabled="disableField"
        focus
        v-mask="'###.###.###-##'"
        :placeholder="$t('document')" 
      />
      <div class="d-flex" style="justify-content: space-between;">
        <div @click="prevStep" class="documentstep-btn">
          <svg style="margin-right: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1L1 6L6 11" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ $t('onboarding.previous') }}
        </div>
        <div v-if="disableNextStep" @click="nextStep" class="documentstep-btn-next">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div v-else class="documentstep-btn-next-disabled">
          {{ $t('onboarding.next') }}
          <svg style="margin-left: 10px;" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11L6 6L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import validator from '@/validators/cpf_cnpj'
import moment from 'moment'
export default {
  name: 'DocumentStep',
  data() {
    return {
      documentNumber: "",
      userLogged: null,
      disableField: false
    }
  },
  computed: {
    documentIsValid() {
      return validator.validate(this.documentNumber);
    },
    disableNextStep() {
      return this.documentIsValid;
    },
  },
  methods: {
    resetAnimation () {
      const documentstep = document.querySelector('.step-documentstep-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 0,
        easing: 'ease-out',
        fill: 'forwards'
      };
      return documentstep.animate(keyframes, options);
    },
    nextStep() {
      this.disableField = true;
      const documentstep = document.querySelector('.step-documentstep-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = documentstep.animate(keyframes, options);
      this.$emit('save-document', { 
        cpf_cnpj: this.documentNumber
      })
      animation.onfinish = () => {
        this.$emit('update-step', { 
          currentStep: 'document', 
          newStep: 'mainobjetive',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_objective"
            },
            {
              meta_key: "onboarding_document",
              meta_value: "done"
            }
          ]
        });
        this.resetAnimation();
      }
    },
    prevStep() {
      const documentstep = document.querySelector('.step-documentstep-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = documentstep.animate(keyframes, options);
      animation.onfinish = () => {
        this.$emit('prev-step', { 
          currentStep: 'documentstep', 
          newStep: 'welcome',
        });
        this.resetAnimation();
      }
    }
  },
  async created() {
    this.userLogged = await this.$store.dispatch('userRequest')
    if (this.userLogged.cpf_cnpj && validator.validate(this.userLogged.cpf_cnpj)) {
      this.documentNumber = this.userLogged.cpf_cnpj
      this.disableField = true;
    }
  }
}
</script>

<style lang="scss">
.step-documentstep-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 60vh;
  max-height: 90vh;
  overflow: auto;
}
.documentstep-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
}
.documentstep-item {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  width: 100%;
  min-height: 70px !important;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
  display: flex;
  align-items: center;
  border: none !important;
  margin-top: 15px;
}

.mainobjective-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.documentstep-title-count-step {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(154, 154, 154, 1);
}
.documentstep-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  margin-right: 6px;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #141414;
  background: #F7F7F7;
  margin-top: 50px;
  margin-bottom: 50px;
}
.documentstep-btn-next {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #009488;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.documentstep-btn-next-disabled {
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: #c4c4c4;
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 50px;
}
.documentstep-select {
  display: flex;
  max-height: 70px;
  min-height: 70px;
  align-items: center;
  border-radius: 10px;
  background: rgba(247, 247, 247, 1);
}
#social-dropdown button:focus {
  outline: none !important;
  border: none;
}
#social-dropdown {
  outline: none;
  border: none;
  width: 100%;
}
#social-dropdown__BV_toggle_ {
  outline: none !important;
  border: none !important;
}
.dropdown-item-social {
  display: flex;
  min-width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 6px;
  padding-right: 9px;
}
</style>